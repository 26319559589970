import React from 'react'
import { Global, css } from '@emotion/core'
import emotionReset from 'emotion-reset'
import Header from './header'
import '../styles/layout.css'

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, darkMode, page }) => 
  {
    return (
      <>
        <Global
          styles={css`

            body {
              -moz-osx-font-smoothing: grayscale;
              text-rendering: optimizeLegibility;
          overflow-x: hidden;
            }
            }
            @-moz-document url-prefix() {
              body .with-ff-fix {
                font-weight: lighter !important;
              }
            }
          `}
        />
        <Header
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          darkMode={darkMode}
          page={page}
        />
        <div>{children}</div>
      </>
    )
  }

export default Layout

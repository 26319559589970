import { css } from "@emotion/core"

export const globalFonts = css`

  @font-face {
    font-family: 'MelindaScript';
    src: url('/fonts/MelindaScript.eot');
    src: url('/fonts/MelindaScript.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MelindaScript.woff2') format('woff2'),
        url('/fonts/MelindaScript.woff') format('woff'),
        url('/fonts/MelindaScript.ttf') format('truetype'),
        url('/fonts/MelindaScript.svg#MelindaScript') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SailecMedium';
    src: url('/fonts/Sailec-Medium.eot');
    src: url('/fonts/Sailec-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Sailec-Medium.woff2') format('woff2'),
        url('/fonts/Sailec-Medium.woff') format('woff'),
        url('/fonts/Sailec-Medium.ttf') format('truetype'),
        url('/fonts/Sailec-Medium.svg#Sailec-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sailec';
    src: url('/fonts/Sailec-Light.eot');
    src: url('/fonts/Sailec-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Sailec-Light.woff2') format('woff2'),
        url('/fonts/Sailec-Light.woff') format('woff'),
        url('/fonts/Sailec-Light.ttf') format('truetype'),
        url('/fonts/Sailec-Light.svg#Sailec-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: "Sailec, Arial";
  }
`

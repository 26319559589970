import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/layout'
import { Global, css } from "@emotion/core"
import { globalFonts } from "../styles/fonts/fontFamilyDefinition"

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      address1
      address2
      zipCode
      city
      country
    }
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
	}
	// console.log("props in layout container: ", props.children[0].props)
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }
        if (!data.companyInfo) {
          throw new Error(
            'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
          )
        }
        return (
          <>
            <Global
              styles={css`
                ${globalFonts}
              `}
            />
						<Layout
							{...props}
							showNav={showNav}
							companyInfo={data.companyInfo}
							siteTitle={data.site.title}
							onHideNav={handleHideNav}
							onShowNav={handleShowNav}
						/>
          </>
        )
      }}
    />
  )
}

export default LayoutContainer

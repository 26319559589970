import React, { useState, setState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'

import { 
	linkActive,
	linkHoverState
} from '../styles/mixins'
import theme from '../styles/theme'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { AppContext } from '../context/stateProvider'
import { motion } from 'framer-motion'
import LogoSVG from "../svgs/umbrella-factor-logo.svg"

const NavLink = ({ url, title }) => {

	return <li>
		<Link
			to={url}
			activeClassName={`active`}
			partiallyActive={true}
		>{title}</Link>
	</li>
}
const Header = ({ darkMode, page }) => {

	const {appState, setAppState} = useContext(AppContext);
	const [isHome, setIsHome] = useState(null)
	const [originalPathname, setOriginalPathname] = useState(null)
	const activateIsHomepage = () => {
		if(location.pathname === '/') {
			setIsHome(true)
		} else {
			setIsHome(false)
		}
	}
	useEffect(() => {
		activateIsHomepage()
	})
	useEffect(() => {
		window.onpopstate = e => {
			//your code...
			activateHomepage()
		}
		// console.log("Window.localStorage: ", window.localStorage.getItem("firstTimer")
		// console.log("window.pathname: ", window.location.pathname)
		// if(window.location.pathname === "/about/") {
		// 	setOriginalPathname('about')
		// } else if (window.location.pathname === "/locations/") {
		// 	setOriginalPathname('location')
		// } else if(window.location.pathname === "/gallery/") {
		// 	setOriginalPathname('gallery')
		// }else if(window.location.pathname === "/contact/") {
		// 	setOriginalPathname('contact')
		// }

	},[])

	function homeClick() {
        setAppState(appState => ({ 
            ...appState,
			curPage: 0,
        }))
    }

	function navClick(i) {
		console.log("nav click")
        /*setAppState(appState => ({ 
            ...appState,
            curPage: i
        }))*/
	}
	const showHomeLink = () => {
		if(appState.curPage === 0 && appState.firstTimer) {
			return false
		} else if(appState.curPage !== 0) {
			return true
		} else {
			return true
		}
	}
	
	console.log("appstate in the header: ", appState)

	return (
		<HeaderSection darkMode={darkMode}>
			<HeaderContainer>
				{showHomeLink() &&
					<HomeLink
						darkMode={darkMode}
						onClick={homeClick}
					>
						<Link to='/'><img src={LogoSVG} alt="Umbrella Factor" /></Link>
					</HomeLink>
				}
				{/* <Hamburger onClick={showNav ? onHideNav : onShowNav}>
						<Icon symbol='hamburger' />
				</Hamburger> */}
				{page !== 'home' &&
					<Nav darkMode={darkMode} className={isHome ? 'is-home' : 'not-home'}>
						<ul>
							<NavLink
								url="/about"
								title={`About`}
								onClick={() => navClick(1)}
								originalPathname={originalPathname}
							/>
							<NavLink
								url="/locations"
								title={`Locations`}
								onClick={() => navClick(2)}
								originalPathname={originalPathname}
							/>
							<NavLink
								url="/gallery"
								title={`Gallery`}
								onClick={() => navClick(3)}
								originalPathname={originalPathname}
							/>
							<NavLink
								url="/contact"
								title={`Contact`}
								onClick={() => navClick(4)}
								originalPathname={originalPathname}
							/>
						</ul>
					</Nav>
				}
			</HeaderContainer>
		</HeaderSection>
	)
}

export default React.memo(Header)

const HeaderSection = styled(motion.header)`
	width:100%;
	/* background-color:${props => props.darkMode === true ? 'black' : theme.colors.white}; */
	background-color:transparent;
	position: fixed;
    z-index: 10001;
`
const HeaderContainer = styled.div`
	justify-content:space-between;
	padding:20px 50px;
	display:flex;

	@media(max-width:${theme.breakpoints.navMobile}) {
		padding:20px 24px;
	}
`

const navLinkDefaults = css`
	font-family:${theme.fonts.body};
	${linkHoverState}
	${linkActive}
	font-size: 1rem;

`
const HomeLink =  styled.div`
	a {
		${navLinkDefaults}
		color: ${theme.colors.white};
		img {
			width:200px;
		}
	}
`
const Hamburger = styled.button`
	/* mobile first bitches! */
	display:block;
	@media(min-width:${theme.breakpoints.navMobile}) {
		display:none;
	}
	`
const Nav = styled(motion.nav)`
	ul {
		display:flex;
		li {
			a {
				${navLinkDefaults}
				margin-left:50px;
				color: ${props => props.darkMode === true ? theme.colors.white : theme.colors.black};
			}
		}
	}

	@media(max-width:${theme.breakpoints.navMobile}) {
		display:none;
	}
`
